// src/components/EmployeeChangeAnalyzer.js

import React, { useState, useEffect } from 'react';
import api from './services/api'; // Adjust the path based on your project structure
import { utils, writeFile } from 'xlsx'; // Importing SheetJS functions

// Import MUI components
import {
  Container,
  Typography,
  Button,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Paper,
  CssBaseline,
} from '@mui/material';

// Import MUI styles
import { styled } from '@mui/material/styles';

function EmployeeChangeAnalyzer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(''); // New state variable
  const [uploadStatus, setUploadStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0); // For progress feedback
  const [records, setRecords] = useState([]); // Original processed data
  const [filteredRecords, setFilteredRecords] = useState([]); // Data after applying filters
  const [warnings, setWarnings] = useState([]); // Warnings from backend

  // Filter States
  const [uniqueFields, setUniqueFields] = useState([]);
  const [uniqueDepartmentCodes, setUniqueDepartmentCodes] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedChangeDateRange, setSelectedChangeDateRange] = useState('90');
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState('');

  // Effect to extract unique 'Field' and 'Department Code' values once records are loaded
  useEffect(() => {
    if (records.length > 0) {
      const fields = [...new Set(records.map(record => record['Field']).filter(Boolean))];
      setUniqueFields(fields);

      const deptCodes = [...new Set(records.map(record => record['Department Code']).filter(Boolean))];
      setUniqueDepartmentCodes(deptCodes);

      const defaultField = 'EmployeeDirectDeposit: AccountNumber';
      setSelectedField(fields.includes(defaultField) ? defaultField : '');

      setSelectedDepartmentCode(deptCodes.includes('500') ? '500' : '');
    }
  }, [records]);

  // Effect to apply filters whenever any filter state changes
  useEffect(() => {
    let tempRecords = [...records];

    if (selectedField) {
      tempRecords = tempRecords.filter(record => record['Field'] === selectedField);
    }

    if (selectedChangeDateRange) {
      const currentDate = new Date();
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - Number(selectedChangeDateRange));

      tempRecords = tempRecords.filter(record => {
        if (!record['Change Date']) return false;
        const changeDate = new Date(record['Change Date']);
        return changeDate >= pastDate && changeDate <= currentDate;
      });
    }

    if (selectedDepartmentCode) {
      tempRecords = tempRecords.filter(record => record['Department Code'] === selectedDepartmentCode);
    }

    setFilteredRecords(tempRecords);
  }, [selectedField, selectedChangeDateRange, selectedDepartmentCode, records]);

  // Handler for file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name); // Update the selected file name
      console.log('Selected file:', file.name);
      setUploadStatus('');
      setProgress(0);
      setRecords([]);
      setFilteredRecords([]);
      setSelectedField('');
      setSelectedChangeDateRange('90');
      setSelectedDepartmentCode('');
      setWarnings([]);
    }
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setUploadStatus('Please select a file before submitting.');
      return;
    }

    setIsLoading(true);
    setUploadStatus('Uploading and processing...');
    setRecords([]);
    setFilteredRecords([]);
    setWarnings([]);

    const formData = new FormData();
    formData.append('excelFile', selectedFile);

    try {
      const response = await api.post('/direct-deposit-change/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      console.log('Upload successful:', response.data);
      setUploadStatus('Upload and processing completed successfully.');
      setRecords(response.data.data);
      setWarnings(response.data.warnings || []);
    } catch (error) {
      console.error('Error uploading file:', error.response?.data || error.message);
      setUploadStatus(`Error: ${error.response?.data?.error || 'Failed to process the file.'}`);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  // Function to export filtered data to Excel with additional sheets
  const exportToExcel = () => {
    if (filteredRecords.length === 0) {
      alert('No records available to export.');
      return;
    }

    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(filteredRecords);
    utils.book_append_sheet(workbook, worksheet, 'FilteredData');

    const employeeNamesInFilteredRecords = filteredRecords.map(record => record['Name']);

    const noActiveStatusWarnings = warnings
      .filter(
        warning =>
          warning.warning === 'No active entries' &&
          employeeNamesInFilteredRecords.includes(warning.employeeName)
      )
      .map(warning => ({
        Employee: warning.employeeName,
        Warning: warning.warning,
      }));

    if (noActiveStatusWarnings.length > 0) {
      const noActiveSheet = utils.json_to_sheet(noActiveStatusWarnings);
      utils.book_append_sheet(workbook, noActiveSheet, 'No Active Status');
    }

    const noMatchedEntriesWarnings = warnings
      .filter(
        warning =>
          warning.warning === 'No matched entries' &&
          employeeNamesInFilteredRecords.includes(warning.employeeName)
      )
      .map(warning => ({
        Employee: warning.employeeName,
        Warning: warning.warning,
      }));

    if (noMatchedEntriesWarnings.length > 0) {
      const noMatchedSheet = utils.json_to_sheet(noMatchedEntriesWarnings);
      utils.book_append_sheet(workbook, noMatchedSheet, 'Employee not found in pApp Data');
    }

    const fileName = `DirectDepositChanges_${new Date().toISOString().slice(0, 10)}.xlsx`;
    writeFile(workbook, fileName);
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <CssBaseline />
      <Typography variant="h4" component="h1" gutterBottom>
        Employee Change Analyzer
      </Typography>

      {/* File Upload Form */}
      <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
        <input
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          id="excelFile"
          type="file"
          onChange={handleFileChange}
          required
        />
        <label htmlFor="excelFile">
          <Button variant="contained" color="primary" component="span">
            {selectedFileName ? `Selected: ${selectedFileName}` : 'Choose File'} {/* Updated Button Label */}
          </Button>
        </label>
        <Typography variant="body2" color="textSecondary">
          Please upload your Employee Change Log
        </Typography>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isLoading}
          sx={{ marginTop: 2 }}
        >
          {isLoading ? 'Processing...' : 'Submit'}
        </Button>
      </form>

      {/* Display Progress Bar */}
      {isLoading && (
        <div style={{ marginTop: 16 }}>
          <LinearProgress variant="determinate" value={progress} style={{ height: '10px' }} />
          <Typography variant="body2" color="textSecondary">
            {progress}%
          </Typography>
        </div>
      )}

      {/* Display Upload Status */}
      {uploadStatus && (
        <Typography
          variant="body1"
          sx={{ marginTop: 2 }}
          color={uploadStatus.toLowerCase().includes('error') ? 'error' : 'textPrimary'}
        >
          {uploadStatus}
        </Typography>
      )}

      {/* Display Filters and Download Button */}
      {records.length > 0 && (
        <Paper elevation={3} sx={{ marginTop: 4, padding: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Filters
          </Typography>
          <Grid container spacing={3}>
            {/* Filter by Field */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Field</InputLabel>
                <Select
                  value={selectedField}
                  onChange={(e) => setSelectedField(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueFields.map((field, index) => (
                    <MenuItem key={index} value={field}>
                      {field}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Change Date */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Change Date</InputLabel>
                <Select
                  value={selectedChangeDateRange}
                  onChange={(e) => setSelectedChangeDateRange(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="30">Last 30 Days</MenuItem>
                  <MenuItem value="60">Last 60 Days</MenuItem>
                  <MenuItem value="90">Last 90 Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Department Code */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Department Code</InputLabel>
                <Select
                  value={selectedDepartmentCode}
                  onChange={(e) => setSelectedDepartmentCode(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueDepartmentCodes.map((dept, index) => (
                    <MenuItem key={index} value={dept}>
                      {dept}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Download Button */}
          {filteredRecords.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={exportToExcel}
              sx={{ marginTop: 2 }}
            >
              Download Data as Excel
            </Button>
          )}
        </Paper>
      )}
    </Container>
  );
}

export default EmployeeChangeAnalyzer;
