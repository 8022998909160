// src/services/config.js

export function getBaseURL() {
  if (window.location.hostname === '72.167.34.236' || window.location.hostname === '127.0.0.1') {
    // Development environment
    return 'http://72.167.34.236:5000/api'; // Replace 'ip' with your actual development server IP
  } else {
    // Production environment
    return 'https://prolifi.app/api';
  }
}
