const ExcelJS = require('exceljs');

const generateExcelFile = async (updatedSingleStoreData, storeInfo, secondaryData, assignedStoreData) => {
  const workbook = new ExcelJS.Workbook();

  // First sheet: Store Totals
  const storeTotalsSheet = workbook.addWorksheet('Store Totals');
  storeTotalsSheet.columns = [
    { header: 'Store Name', key: 'storeName', width: 30 },
    { header: 'Total Vision', key: 'totalVision', width: 15 },
    { header: 'Total Dental', key: 'totalDental', width: 15 },
  ];

  // Set header style
  storeTotalsSheet.getRow(1).font = { bold: true, size: 12 };

  // Calculate the totals for each store
  const storeTotals = {};
  
  console.log("USSD: ", updatedSingleStoreData);

  for (const store in updatedSingleStoreData) {
    updatedSingleStoreData[store].forEach(employee => {
      const storeName = storeInfo[employee.homeStore] || 'EGM';

      // Log entries with 'EGM' in store totals calculation
      if (storeName === 'EGM') {
        console.log(`Classified as EGM in Totals: Identifier ${employee.identifier}, Name ${employee.FN} ${employee.LN}, Products: ${JSON.stringify(employee.products)}`);
      }

      if (!storeTotals[storeName]) {
        storeTotals[storeName] = { totalVision: 0, totalDental: 0 };
      }

      // Process each product's totDue
      employee.products.forEach(product => {
        if (product.product === 'VISION') {
          storeTotals[storeName].totalVision += product.totDue;
        }
        if (product.product === 'DENTAL') {
          storeTotals[storeName].totalDental += product.totDue;
        }
      });

      // Process retroactivity adjustments for each employee
      if (employee.retroactivity && Array.isArray(employee.retroactivity)) {
        employee.retroactivity.forEach(retro => {
          if (retro.product === 'VISION') {
            storeTotals[storeName].totalVision += retro.adjAmnt;
            //console.log(`Adding retroactivity adjustment of ${retro.adjAmnt} to Vision for ${storeName}`);
          }
          if (retro.product === 'DENTAL') {
            storeTotals[storeName].totalDental += retro.adjAmnt;
            //console.log(`Adding retroactivity adjustment of ${retro.adjAmnt} to Dental for ${storeName}`);
          }
        });
      }
    });
  }

  // Convert storeTotals to array and sort by storeName
  const sortedStoreTotals = Object.keys(storeTotals).map(storeName => ({
    storeName: storeName,
    totalVision: storeTotals[storeName].totalVision,
    totalDental: storeTotals[storeName].totalDental
  })).sort((a, b) => a.storeName.localeCompare(b.storeName));

  // Populate the store totals sheet
  sortedStoreTotals.forEach(store => {
    storeTotalsSheet.addRow(store);
  });

  // Add store totals
  storeTotalsSheet.addRow({});
  storeTotalsSheet.addRow({
    storeName: 'Store Totals:',
    totalVision: Object.values(storeTotals).reduce((acc, curr) => acc + curr.totalVision, 0),
    totalDental: Object.values(storeTotals).reduce((acc, curr) => acc + curr.totalDental, 0),
  });

  // Calculate and add secondary data adjustments
  const visionAdjustments = secondaryData.filter(entry => entry.product === 'VISION').reduce((acc, curr) => acc + curr.adjAmnt, 0);
  const dentalAdjustments = secondaryData.filter(entry => entry.product === 'DENTAL').reduce((acc, curr) => acc + curr.adjAmnt, 0);


// Second sheet: Employee Details
const employeeDetailsSheet = workbook.addWorksheet('Employee Details');
employeeDetailsSheet.columns = [
  { header: 'Store Name', key: 'storeName', width: 30 },
  { header: 'Employee Name', key: 'employeeName', width: 30 },
  { header: 'Vision Total', key: 'visionTotal', width: 15 },
  { header: 'Dental Total', key: 'dentalTotal', width: 15 },
];

// Set header style
employeeDetailsSheet.getRow(1).font = { bold: true, size: 12 };

// Create a list of unique entries using identifier
const uniqueEntries = {};

for (const store in updatedSingleStoreData) {
  updatedSingleStoreData[store].forEach(employee => {
    if (!uniqueEntries[employee.identifier]) {
      uniqueEntries[employee.identifier] = employee;
    }
  });
}

// Convert uniqueEntries to array and sort by storeName
const sortedUniqueEntries = Object.values(uniqueEntries).sort((a, b) => {
  const storeNameA = storeInfo[a.products[0].homeStore] || 'EGM';
  const storeNameB = storeInfo[b.products[0].homeStore] || 'EGM';
  return storeNameA.localeCompare(storeNameB);
});

// Go through the list of unique entries and consolidate totals
sortedUniqueEntries.forEach(employee => {
  const storeName = storeInfo[employee.products[0].homeStore] || 'EGM';

  // Initialize base and retro totals for vision and dental
  let visionBaseTotal = employee.products.find(product => product.product === 'VISION')?.totDue || 0;
  let dentalBaseTotal = employee.products.find(product => product.product === 'DENTAL')?.totDue || 0;

  let visionRetroTotal = 0;
  let dentalRetroTotal = 0;

  // Process retroactivity for all employees
  if (employee.retroactivity && Array.isArray(employee.retroactivity)) {
    employee.retroactivity.forEach(retro => {
      if (retro.product === 'VISION') {
        visionRetroTotal += retro.adjAmnt;
      }
      if (retro.product === 'DENTAL') {
        dentalRetroTotal += retro.adjAmnt;
      }
    });
  }

  // Create a consolidated object for each employee
  const employeeConsolidatedTotals = {
    employeeName: `${employee.FN} ${employee.LN}`,
    dental: {
      base: dentalBaseTotal,
      retro: dentalRetroTotal,
    },
    vision: {
      base: visionBaseTotal,
      retro: visionRetroTotal,
    },
  };

  // Log the consolidated totals object
  //console.log(employeeConsolidatedTotals);

  // You can still add the row to the Excel sheet if needed (optional)
  employeeDetailsSheet.addRow({
    storeName: storeName,
    employeeName: `${employee.FN} ${employee.LN}`,
    visionTotal: visionBaseTotal + visionRetroTotal,
    dentalTotal: dentalBaseTotal + dentalRetroTotal,
  });
});



  // Third sheet: Employees Needing Assignment
  const needsAssignmentSheet = workbook.addWorksheet('Needed Assignment');
  needsAssignmentSheet.columns = [
    { header: 'Employee Name', key: 'employeeName', width: 30 },
    { header: 'Assigned Store', key: 'assignedStore', width: 30 },
  ];

  // Set header style
  needsAssignmentSheet.getRow(1).font = { bold: true, size: 12 };

  // Sort assignedStoreData by storeName
  const sortedAssignedStoreData = assignedStoreData.sort((a, b) => {
    const storeNameA = storeInfo[a.assignedStore] || 'EGM';
    const storeNameB = storeInfo[b.assignedStore] || 'EGM';
    return storeNameA.localeCompare(storeNameB);
  });

  // Populate the needs assignment sheet
  //console.log('Assigned Store Data: ', assignedStoreData);
  sortedAssignedStoreData.forEach(employee => {
    const storeName = storeInfo[employee.assignedStore] || 'EGM';
    needsAssignmentSheet.addRow({
      employeeName: employee.employeeName,
      assignedStore: storeName,
    });
  });

  // Generate buffer
  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

module.exports = generateExcelFile;
