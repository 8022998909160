//App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import BaseLayout from './components/BaseLayout';
import Login from './components/Login';
import PrFileUpload from './components/prFileUpload';
import SScheduleAnalyzer from './components/SScheduleAnalyzer';
import PdfReview from './components/PdfReview';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound'; // Assuming you have a component for 404 pages
import BbUpload from './components/bbUpload';
import UserCreationForm from './components/UserCreationForm';
import WManual from './components/W-Manual';
import WeeklySalesReport from './components/WeeklySalesReport';
import axios from 'axios';
import LoadingIndicator from './LoadingIndicator';
import PopsReport from './components/PopsReport';
import ExcelProcessor from './components/EEDataUpload';
import TZFileUpload from './components/TZFileUpload';
import GradingStandardsEditor from './components/GradingStandardsEditor';
import FraudPrevention from './components/FraudPrevention';
import StoreDashboard from './components/storeLevel/StoreDashboard';
import MonthlyProcessing from './components/Monthly/MonthlyProcessing';
import MonthlyManual from './components/Monthly/MonthlyManual';
import BusinessPage from './components/biz/BusinessPage';
import Blog from './components/biz/Blog';
import SalesForecaster from './components/SalesForecaster';
import StickerComponent from './components/StickerComponent';
import ForecastDisplay from './components/ForecastDisplay';
import StoreUserManagement from './components/StoreUserManagement';
import CorrectiveActionDashboard from './components/CorrectiveActionDashboard';
import VisionDental from './components/VisionDental';
import SessionChecker from './components/SessionChecker';
import PLReview from './components/PLReview';
import ExcelUploader from './components/ExcelUploader';
import EmployeeAnalyzer from './components/EmployeeAnalyzer';
import EmployeeDataViewer from './components/EeAffinity.js';
import ClosingCheckListBuilder from './components/ClosingCheckListBuilder';
import CSVConverter from './components/TruckConverter';
import PrivacyPolicy from './components/PrivacyPolicy';
import DirectDepositChange from './components/DirectDepositChange';

function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route index element={<div>Dashboard</div>} />
      <Route path="prFileUpload" element={<PrFileUpload />} />
      <Route path="sScheduleAnalyzer" element={<SScheduleAnalyzer />} />
      <Route path="pdfReview" element={<PdfReview />} />
      <Route path="bbUpload" element={<BbUpload />} />
      <Route path="create-user" element={<UserCreationForm />} />
      <Route path="w-manual" element={<WManual />} />
      <Route path="weekly-sales-report" element={<WeeklySalesReport />} />
      <Route path="pops-report" element={<PopsReport />} />
      <Route path="ee-data-upload" element={<ExcelProcessor />} />
      <Route path="tz-file-upload" element={<TZFileUpload />} />
      <Route path="fraud-prevention" element={<FraudPrevention />} />
      <Route path="monthly-processing" element={<MonthlyProcessing />} />
      <Route path="monthly-manual" element={<MonthlyManual />} />
      <Route path="grading-standards" element={<GradingStandardsEditor />} />
	  <Route path="sales-forecaster" element={<SalesForecaster />} />
	  <Route path="sticker-component" element={<StickerComponent />} />
	  <Route path="forecast-display" element={<ForecastDisplay />} />
	  <Route path="store-user-management" element={<StoreUserManagement />} />
	  <Route path="corrective-action-dashboard" element={<CorrectiveActionDashboard />} />
	  <Route path="vision-dental" element={<VisionDental />} />
	  <Route path="session-checker" element={<SessionChecker />} />
	  <Route path="pl-review" element={<PLReview />} />
	  <Route path="shift-analyze" element={<ExcelUploader />} />
	  <Route path="employee-analyzer" element={<EmployeeAnalyzer />} />
	  <Route path="employee-data-viewer" element={<EmployeeDataViewer />} />
	  <Route path="closing-checklist-builder" element={<ClosingCheckListBuilder />} />
	  <Route path="/csv-converter" element={<CSVConverter />} />
	  <Route path="/direct-deposit-change" element={<DirectDepositChange />} />
    </Routes>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 
  const location = useLocation(); 

  const checkAuthStatus = async () => {
    // Determine the base URL based on window location
    const baseUrl = window.location.hostname === '72.167.34.236'
      ? 'http://72.167.34.236:5000' // Development server
      : 'https://prolifi.app'; // Production server

    try {
      const response = await axios.get(`${baseUrl}/api/auth/session-status`, { withCredentials: true });
      setIsAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      console.error('Error checking authentication status:', error);
      setIsAuthenticated(false);
    }
	setLoading(false);
  };

  useEffect(() => {
    if (location.pathname !== "/login") {
      checkAuthStatus();
    } else {
      setLoading(false);  // If user is on login page, don't need to check auth status
    }
  }, [location]);
  
		if (loading) {
		  return (
			<div className="loading-indicator-container">
			  <LoadingIndicator />
			</div>
		  );
		}


  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };
  
    const BaseLayoutRoutes = () => (
    <Routes>
      <Route index element={<div>Dashboard</div>} />
      <Route path="prFileUpload" element={<PrFileUpload />} />
      <Route path="sScheduleAnalyzer" element={<SScheduleAnalyzer />} />
      <Route path="pdfReview" element={<PdfReview />} />
      <Route path="bbUpload" element={<BbUpload />} />
      <Route path="create-user" element={<UserCreationForm />} />
      <Route path="w-manual" element={<WManual />} />
      <Route path="weekly-sales-report" element={<WeeklySalesReport />} />
      {/* Other nested routes */}
    </Routes>
  );

  return (
    <div>
      <Routes>
	  		<Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route
          path="/login"
          element={<Login onLoginSuccess={handleLoginSuccess} />}
        />
		
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Directly accessible store dashboard route */}
        <Route path="/store/*" element={<StoreDashboard />} />

		{/* The new Business Page route */}
        <Route path="/business" element={<BusinessPage />} />
		<Route path="/blog" element={<Blog />} />
        {/* The BaseLayout route */}
        <Route
          path="/"
          element={
            isAuthenticated ? <BaseLayout /> : <Navigate to="/login" replace />
          }
        >
          {/* Nested routes protected by authentication will be rendered */}
          {isAuthenticated && <Route path="/*" element={<AuthenticatedRoutes />} />}
        </Route>

        {/* If none of the above routes match, show a 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
