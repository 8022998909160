import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MultiStoreModal = ({ needsAssignment, singleStoreData, onAssign, assignments, setAssignments, onClose }) => {
	//console.log("Single Store Data: ", singleStoreData);
  useEffect(() => {
    //console.log('MultiStoreModal received needsAssignment:', needsAssignment);
  }, [needsAssignment]);

  const handleSelectChange = (event, identifier) => {
    const { value } = event.target;
    setAssignments(prev => ({
      ...prev,
      [identifier]: value
    }));
  };

  const handleSubmit = () => {
    const updatedSingleStoreData = { ...singleStoreData };
    const assignmentData = {};
    const assignedStoreData = [];

    for (const identifier in assignments) {
      const store = assignments[identifier];

      // Find the matching entry in needsAssignment
      const entries = needsAssignment.filter(entry => entry.identifier === identifier);

      entries.forEach(entry => {
        if (!updatedSingleStoreData[store]) {
          updatedSingleStoreData[store] = [];
        }
        // Add homeStore property to each product entry
        const entryWithHomeStore = { ...entry, products: entry.products.map(product => ({ ...product, homeStore: store })) };
        updatedSingleStoreData[store].push(entryWithHomeStore);

        if (!assignmentData[store]) {
          assignmentData[store] = [];
        }
        assignmentData[store].push({
          name: `${entry.FN} ${entry.LN}`,
          product: entry.product,
          identifier: entry.identifier,
          totDue: entry.totDue
        });

        // Add to assignedStoreData
        assignedStoreData.push({
          employeeName: `${entry.FN} ${entry.LN}`,
          assignedStore: store
        });
      });
    }

    console.log('Updated Single Store Data (in modal):', updatedSingleStoreData);
    console.log('Assignment Data:', assignmentData);
    console.log('Assigned Store Data:', assignedStoreData);

    onAssign(updatedSingleStoreData, {}, assignmentData, assignedStoreData);
  };

  // Create a unique list of employees based on identifier
  const uniqueEmployees = {};
  needsAssignment.forEach(entry => {
    if (!uniqueEmployees[entry.identifier]) {
      uniqueEmployees[entry.identifier] = entry;
    }
  });
  


  return (
    <Modal
      open={true}
      onClose={onClose} // Close modal gracefully
      aria-labelledby="assign-employees-modal-title"
      aria-describedby="assign-employees-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '80%',
          overflowY: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
          <Typography id="assign-employees-modal-title" variant="h6" component="h2">
            Assign Employees to Store or EGM
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {Object.values(uniqueEmployees).map(entry => (
          <Grid container spacing={1} alignItems="center" key={entry.identifier} sx={{ marginBottom: '8px' }}>
            <Grid item xs={6}>
              <Typography>{entry.FN} {entry.LN}</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ margin: '0', minHeight: '36px' }}>
                <InputLabel>Assign to</InputLabel>
                <Select
                  value={assignments[entry.identifier] || ''}
                  onChange={(e) => handleSelectChange(e, entry.identifier)}
                  sx={{ minHeight: '36px' }}
                >
                  <MenuItem value="">Select store or EGM</MenuItem>
                  {entry.stores && entry.stores.map(storeEntry => (
                    <MenuItem key={`${entry.identifier}-${storeEntry.store}`} value={storeEntry.store}>
                      {storeEntry.storeName} - {storeEntry.department}
                    </MenuItem>
                  ))}
                  <MenuItem value="EGM">EGM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MultiStoreModal;
