// src/services/api.js

import axios from 'axios';
import { getBaseURL } from './config';

// Create an Axios instance
const api = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies
});

// Optional: Add interceptors for request/response if needed
// For example, to handle authentication tokens

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Example: Attach an authentication token if available
    const token = localStorage.getItem('authToken'); // Adjust based on your auth strategy
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Example: Handle unauthorized errors globally
    if (error.response && error.response.status === 401) {
      // Redirect to login or show a modal
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
